import React, { Component } from "react";

class AGB extends Component {
  render() {
    return (
      <div>
        <section className="datenschutz">
          <h1>Allgemeine Geschäftsbedingungen</h1>

          <p>
            Familie Wicht <br />
            Steinriegel 1 <br />
            8442 Kitzeck im Sausal <br />
            <a href={`tel:+436645224635`}>+43 664 522 46 35</a>
            <br />
            <a href={`tel:+436641293166`}>+43 664 129 31 66</a>
          </p>

          <p className="p1">
            <span className="s1">
              <strong>1. Geltungsbereich</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Diese Allgemeinen Verkaufs- und Lieferbedingungen gelten
              ausschlie&szlig;lich f&uuml;r alle Lieferungen und Waren welche
              durch Weingut Wicht aufgrund von Bestellungen im Fernabsatz
              (online) mit Verbrauchern im Sinne des Konsumentenschutzgesetzes
              erfolgen. Mit Bestellung bzw. sp&auml;testens mit Empfang der Ware
              erkennt der Kunde diese Allgemeinen Verkaufs- und
              Lieferbedingungen an.{" "}
            </span>
            <span className="s2">
              <br />{" "}
            </span>
            <span className="s3">
              b) Ab&auml;nderungen oder Nebenbedingungen bed&uuml;rfen zu ihrer
              G&uuml;ltigkeit der schriftlichen Best&auml;tigung durch im
              Firmenbuch eingetragene vertretungsbefugte Personen unseres
              Unternehmens und gelten nur f&uuml;r den einzelnen
              Gesch&auml;ftsfall. Unsere &uuml;brigen Mitarbeiter sind nicht
              bevollm&auml;chtigt, &Auml;nderungen oder Nebenabreden zu diesen
              Allgemeinen Verkaufs- und Lieferbedingungen zu vereinbaren.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Diese Gesch&auml;ftsbedingungen gelten als Rahmenbedingungen
              auch f&uuml;r alle weiteren Rechtsgesch&auml;fte zwischen den
              Vertragspartnern.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Sollten einzelne Punkte dieser Allgemeinen Verkaufs- und
              Lieferbedingungen unwirksam sein, so ber&uuml;hrt dies die
              G&uuml;ltigkeit der &uuml;brigen Bestimmungen und der unter ihrer
              Zugrundelegung geschlossenen Vertr&auml;ge nicht. Die Parteien
              werden anstelle der unwirksamen Bestimmung eine wirksame, die ihr
              dem Sinn und Zweck nach am n&auml;chsten kommt, vereinbaren.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>2. Vertragsabschuss</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Die Bestellung des Kunden stellt ein Angebot an Weingut Wicht.
              eines Kaufvertrages dar. Nach Eingang der Bestellung wird Weingut
              Wicht. den Kunden &uuml;ber den Eingang seiner Bestellung per
              E-Mail informieren (Bestellbest&auml;tigung). Die
              Bestellbest&auml;tigung stellt keine Annahme des Angebotes des
              Bestellers dar. Der Kaufvertrag kommt mit Annahme des Angebotes
              durch Weingut Wicht. zustande. Die Annahme erfolgt durch die per
              E-Mail &uuml;bersandte Versandbest&auml;tigung oder durch
              Lieferung der Ware. Weingut Wicht. kann das Angebot innerhalb von
              zwei Wochen ab Eingang des Angebotes des Bestellers bei Weingut
              Wicht. annehmen.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Wir sind berechtigt, Bestellungen auch nur zum Teil anzunehmen.
              Bei teilbaren Leistungen hat der Kunde kein R&uuml;cktrittsrecht
              betreffend lieferbare Teile, soweit Teile der Leistung
              erf&uuml;llbar und f&uuml;r den K&auml;ufer verwendbar sind.{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Eine Bestellung ist nur m&ouml;glich, wenn alle Pflichtfelder
              (mit einem Stern versehene Felder) ausgef&uuml;llt sind. Der
              Eingang der Bestellung bei uns wird durch ein automatisch
              versandtes Mail best&auml;tigt, das aber keine Annahme der
              Bestellung bedeutet. Nachrichten gehen uns nur w&auml;hrend der{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              normalen Gesch&auml;ftszeiten (Mo. &ndash; Fr. von 9.00 bis 17.00
              Uhr mit Ausnahme von gesetzlichen Feiertagen in &Ouml;sterreich)
              zu. Au&szlig;erhalb dieser Zeiten auf unserem Server einlangende
              Nachrichten gelten erst am n&auml;chsten Arbeitstag als
              zugegangen.{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Dem Kunden ist bekannt, dass das Internet kein sicheres
              Kommunikationsmedium ist und dass Daten, die &uuml;ber das
              Internet versandt werden, einerseits bekannt werden k&ouml;nnen
              und andererseits von Dritten ver&auml;ndert werden k&ouml;nnen.
              Der Kunde tr&auml;gt das Risiko, dass Daten nicht oder nicht in
              der von ihm gesandten Form bei uns ankommen. Wir d&uuml;rfen
              darauf vertrauen, dass die Daten in der Form, in der wir sie
              erhalten, vom Kunden gesandt wurden.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>3. Preise</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Die in unserem Online-Shop angegebenen Preise verstehen sich
              inklusive der gesetzlichen &ouml;sterreichischen Umsatzsteuer und
              beinhalten alle anfallenden Lieferkosten innerhalb
              &Ouml;sterreichs, jedoch keine anderen Nebenleistungen, sofern
              nicht in der jeweils geltenden Preisliste andere Lieferkonditionen
              angef&uuml;hrt sind. Bei Lieferungen au&szlig;erhalb
              &Ouml;sterreichs tr&auml;gt der K&auml;ufer alle durch den Versand
              entstehenden Spesen, einschlie&szlig;lich der jeweils geltenden
              Aus- und Einfuhrabgaben.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">4. Lieferung und Lieferzeit</span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Der Versand erfolgt auf Gefahr des Kunden an die von ihm bei
              der Bestellung angegebene Lieferadresse.{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Die Lieferung erfolgt durch Weingut Wicht. oder einen
              beauftragten Spediteur zu gesch&auml;fts&uuml;blichen Zeiten. Im
              Fall der Nichtannahme von bestellter Ware ist Weingut Wicht.
              berechtigt, den Ersatz der dadurch entstandenen Mehraufwendung,
              wie z.B. frustrierte Transportkosten, zu verlangen. Das gilt
              nicht, wenn Weingut Wicht ihre Leistungen nicht
              vertragsgem&auml;&szlig; erbringt.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Die Angabe von Lieferterminen erfolgt unverbindlich. Die
              Nichteinhaltung der Liefertermine berechtigt den Kunden jedenfalls
              erst dann zur Geltendmachung des R&uuml;cktrittsrechts, wenn wir
              trotz schriftlicher Setzung einer mindestens zweiw&ouml;chigen
              Nachfrist die Lieferung nicht durchf&uuml;hren.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Die Lieferfrist wird durch alle vom Parteiwillen
              unabh&auml;ngigen Umst&auml;nden, wie z.B. h&ouml;herer Gewalt,
              unvorhersehbare Betriebsst&ouml;rungen, Beh&ouml;rdliche
              Eingriffe, Transport- und Verzollungsverzug,
              Transportsch&auml;den, Ausschuss wichtiger Fertigungsteile und
              Arbeitskonflikte, um die Dauer der Hinderung verl&auml;ngert.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              e) Soweit Teillieferungen m&ouml;glich sind, sind sie auch
              rechtlich zugelassen. Jede Teillieferung gilt als eigenes
              Gesch&auml;ft und kann von uns gesondert in Rechnung gestellt
              werden.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>5. Gew&auml;hrleistung</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Gew&auml;hrleistungsanspr&uuml;che setzen voraus, dass uns
              M&auml;ngel unverz&uuml;glich angezeigt werden, und zwar
              erkennbare M&auml;ngel sofort bei &Uuml;bernahme, versteckte
              M&auml;ngel nach Entdeckung, und unter Vorlage der angebrochenen
              Ware.{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Handels&uuml;bliche oder geringf&uuml;gige, technisch bedingte
              Abweichungen der Qualit&auml;t, Quantit&auml;t, Farbe,
              Gr&ouml;&szlig;e, des Gewichts, der Ausr&uuml;stung oder des
              Designs stellen weder Gew&auml;hrleistungsm&auml;ngel noch
              Nichterf&uuml;llung des Vertrages dar.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Weingut Wicht. haftet nicht f&uuml;r geschmackliche, farbliche,
              materialm&auml;&szlig;ige oder sonstige &Uuml;bereinstimmung von
              nachbestellter Ware. Entsprechendes gilt auch f&uuml;r nach Muster
              bestellte Ware, soweit sich die Abweichung in den
              handels&uuml;blichen und technischen Grenzen h&auml;lt.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
            <span className="s3">
              d) F&uuml;r jede Art von Lieferung verj&auml;hren Anspr&uuml;che
              aus M&auml;ngeln &ndash; unabh&auml;ngig auf welchen Rechtsgrund
              sie gest&uuml;tzt werden &ndash; innerhalb der gesetzlichen
              Gew&auml;hrleistungsfrist von zwei Jahren ab Lieferung bzw.
              Leistung. F&uuml;r Konsumenten beginnt diese Frist bei
              Rechtsm&auml;ngeln erst ab Erkennbarkeit des Mangels.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              e) Der Kunde kann bei Mangelhaftigkeit der Sache den Austausch der
              Sache verlangen. Nur wenn dies untunlich ist, kann der Kunde
              angemessene Preisminderung oder Wandlung begehren.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>6. Schadenersatz</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Weingut Wicht. &uuml;bernimmt keinerlei Gew&auml;hr f&uuml;r die
              Aktualit&auml;t, Korrektheit, Vollst&auml;ndigkeit oder
              Qualit&auml;t der bereitgestellten Informationen.
              Haftungsanspr&uuml;che gegen Weingut Wicht., welche sich auf
              Sch&auml;den materieller oder ideeller Arte beziehen, die durch
              die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
              durch die Nutzung fehlerhafter oder unvollst&auml;ndiger
              Informationen verursacht wurden, sind grunds&auml;tzlich
              ausgeschlossen, sofern Weingut Wicht. nicht vors&auml;tzliches
              oder gro&szlig; fahrl&auml;ssiges Verschulden nachgewiesen wird.
              Alle Angebote sind freibleibend und unverbindlich. Weingut Wicht.
              beh&auml;lt es sich ausdr&uuml;cklich vor, Teile der Seiten oder
              gesamte Angebot ohne gesonderte Ank&uuml;ndigung zu
              ver&auml;ndern, zu erg&auml;nzen, zu l&ouml;schen oder die
              Ver&ouml;ffentlichung zeitweise oder endg&uuml;ltig einzustellen.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s4">
              <strong>7. Zahlungsbedingungen</strong>
            </span>
            <span className="s5">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Unsere Rechnungen sind unverz&uuml;glich vor Beginn der
              Lieferfrist entweder per Vorauskasse oder{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              mit einer der folgenden Zahlungsmethoden zu begleichen: SOFORT
              &Uuml;berweisung, PAYPAL. Die Daten von Kreditkarten werden in
              keiner Form gespeichert.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Bei versp&auml;teter Zahlung berechnen wir Verzugszinsen im
              &uuml;blichen Umfange.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Bei Verzug des Kunden mit Zahlung oder seiner sonstigen
              Leistungen sind wir &ndash; unbeschadet sonstiger Rechte &ndash;
              berechtigt, unsere Lieferungen bis zur Erbringung der vereinbarten
              Gegenleistung unter Wahrung der noch offenen Lieferfrist
              zur&uuml;ckzubehalten oder nach Verstreichen einer angemessenen
              Nachfrist vom Vertrag zur&uuml;ckzutreten und Schadenersatz wegen
              Nichterf&uuml;llung zu verlangen. In diesem Fall hat der Kunde die
              gelieferten Gegenst&auml;nde unverz&uuml;glich auf seine Kosten an
              uns zur&uuml;ckzusenden. Die Geltendmachung von
              Schadenersatzanspr&uuml;chen beh&auml;lt sich Weingut Wicht. vor.{" "}
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              d) Der Kunde verpflichtet sich bei Verletzung seiner vertraglichen
              Verpflichtungen, alle uns zur zweckentsprechenden Verfolgung
              unserer Anspr&uuml;che notwendigen Kosten zu ersetzen,
              insbesondere die Kosten von Inkassob&uuml;ros bis zu den in der
              jeweils geltenden Verordnung f&uuml;r H&ouml;chstgeb&uuml;hren im
              Inkassowesen vorgesehenen H&ouml;chstgeb&uuml;hren und die Kosten
              von Rechtsanw&auml;lten nach dem Rechtsanwaltstarifgesetz zu
              ersetzen.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>8. Eigentumsvorbehalt</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Alle Waren werden von uns unter Eigentumsvorbehalt geliefert und
              bleiben bis zur vollst&auml;ndigen Bezahlung des Preises inklusive
              aller Nebenkosten unser alleiniges und unbeschr&auml;nktes
              Eigentum.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>9. Datenschutz</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Sofern innerhalb des Internetangebotes die M&ouml;glichkeit zur
              Eingabe pers&ouml;nlicher oder gesch&auml;ftlicher Daten
              (Emailadressen, Namen, Anschriften, sonstige Daten) besteht, so
              erfolgt die Preisgabe dieser Daten auf freiwilliger Basis. Es kann
              jederzeit Auskunft erteilt werden. Vorhandene Daten f&uuml;r
              Buchhaltung und Kundenbetreuung bleiben jedoch im Rahmen der
              gesetzlichen Vorschriften gespeichert.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>10. Adress&auml;nderung</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Der Kunde ist verpflichtet, uns &Auml;nderungen seiner Wohn- bzw.
              Gesch&auml;ftsadresse bekanntzugeben, solange das
              vertragsgegenst&auml;ndige Rechtsgesch&auml;ft nicht beiderseitig
              vollst&auml;ndig erf&uuml;llt ist. Wird die Mitteilung
              unterlassen, so gelten Erkl&auml;rungen auch dann als zugegangen,
              falls sie an die zuletzt bekanntgegebene Adresse gesendet werden.
              Bei unrichtigen, unvollst&auml;ndigen und unklaren Angaben durch
              den Kunden haftet dieser f&uuml;r alle uns daraus entstehenden
              Kosten.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>11. R&uuml;cktrittsrecht nach &sect; 5 e KSchG</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Der Kunde, der Verbraucher im Sinne des KSchG ist, kann von
              einem im Fernabsatz geschlossenen Vertrag oder einer im Fernabsatz
              abgegebenen Vertragserkl&auml;rung bis zum Ablauf der im folgenden
              genannten Fristen zur&uuml;cktreten. Es gen&uuml;gt, wenn die
              R&uuml;cktrittserkl&auml;rung innerhalb der Frist abgesendet wird.
              Die R&uuml;cktrittsfrist betr&auml;gt sieben Werktage, wobei der
              Samstag nicht als Werktag z&auml;hlt. Sie beginnt bei
              Vertr&auml;gen &uuml;ber die Lieferung von Waren mit dem Tag ihres
              Eingangs beim Kunden, bei Vertr&auml;gen &uuml;ber die Erbringung
              von Dienstleistungen mit dem Tag des Vertragsabschlusses. Sind wir
              unseren Informationspflichten nach &sect; 5d Abs 1 und 2 KSchG
              nicht nachgekommen, so betr&auml;gt die R&uuml;cktrittsfrist drei
              Monate ab dem im vorigen Satz genannten Zeitpunkten. Kommen wir
              unseren Informationspflichten innerhalb dieser Frist nach, so
              beginnt mit dem Zeitpunkt der &Uuml;bermittlung der Informationen
              durch uns die im vorigen Satz genannte Frist zur Aus&uuml;bung des
              R&uuml;cktrittsrechts.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Der Kunde hat kein R&uuml;cktrittsrecht bei Vertr&auml;gen
              &uuml;ber
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              1. Dienstleistungen, die mit der Ausf&uuml;hrung dem Kunden
              gegen&uuml;ber vereinbarungsgem&auml;&szlig; innerhalb von sieben
              Werktagen (&sect; 5e Abs 2 erster Satz KSchG) ab Vertragsabschluss
              begonnen wird,
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              2. Waren oder Dienstleistungen, deren Preis von der Entwicklung
              der S&auml;tze auf den Finanzm&auml;rkten, auf die wir keinen
              Einfluss haben, abh&auml;ngt,
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              3. Waren, die nach Kundenspezifikationen angefertigt werden, die
              eindeutig auf die pers&ouml;nlichen Bed&uuml;rfnisse zugeschnitten
              sind, die auf Grund ihrer Beschaffenheit nicht f&uuml;r eine
              R&uuml;cksendung geeignet sind, die schnell verderben k&ouml;nnen
              oder deren Verfallsdatum &uuml;berschritten wurde,
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">4. Wertgutscheine und Gutschriften,</span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              5. Hauslieferungen oder Freizeit-Dienstleistungen (&sect; 5c Abs 4
              Z 1 und 2 KSchG).
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Somit hat der Kunde kein R&uuml;ckgaberecht bei Bestellung von
              Weingut Wicht. oder anderen Produkten des Onlineshops von Weingut
              Wicht.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Bei Aus&uuml;bung des R&uuml;cktrittsrechts gem&auml;&szlig;
              Abs a haben Zug um Zug{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              1. wir die vom Kunden geleisteten Zahlungen zu erstatten und den
              vom Kunden auf die Sache gemachten notwendigen und n&uuml;tzlichen
              Aufwand zu ersetzen sowie
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              2. der Kunde die empfangene Leistung zur&uuml;ckzustellen (wobei
              die unmittelbaren Kosten der R&uuml;cksendung
              vereinbarungsgem&auml;&szlig; vom Kunden zu tragen sind) und uns
              ein angemessenes Entgelt f&uuml;r die Ben&uuml;tzung,
              einschlie&szlig;lich einer Entsch&auml;digung f&uuml;r eine damit
              verbundene Minderung des{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              gemeinen Werts der Leistung, zu zahlen; die &Uuml;bernahme der
              Leistungen in die Gewahrsame des Kunden ist f&uuml;r sich allein
              nicht als Wertminderung anzusehen.{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <strong>12. Sonstiges</strong>
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              a) Eine &Uuml;bertragung der Rechte aus dem mit uns
              abgeschlossenem Vertrag an Dritte bedarf unserer schriftlichen
              Zustimmung.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>
                Allgemeine Gesch&auml;ftsbedingungen von Weingut Wicht.
              </strong>
            </span>
            <span className="s2">
              <strong>
                <br />{" "}
              </strong>
            </span>
            <span className="s3">
              <strong>
                (gelten nur f&uuml;r den kaufm&auml;nnischen Bereich, nicht
                f&uuml;r Verbraucher){" "}
              </strong>
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>1. Geltungsbereich </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Die nachbezeichneten Bestimmungen gelten f&uuml;r alle zwischen
              Weingut Wicht. (im folgenden Weingut Wicht) und dem K&auml;ufer
              abgeschlossene Vertr&auml;ge, sowie f&uuml;r alle sonstigen
              Lieferungen und Leistungen. Der K&auml;ufer nimmt zur Kenntnis,
              dass die AGB Grundlage f&uuml;r alle wichtigen k&uuml;nftigen
              Gesch&auml;ftsbeziehungen mit Weingut Wicht sind.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              b) Der K&auml;ufer erkennt diese Bedingungen an. Der
              Vertragsabschluss gilt als Anerkenntnis. Abweichende Bedingungen
              des K&auml;ufers m&uuml;ssen zu deren G&uuml;ltigkeit
              ausdr&uuml;cklich von Weingut Wicht schriftlich anerkannt werden.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>2. Vertragsabschluss </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Die Angebote und die Preisangaben von Weingut Wicht sind
              freibleibend und unverbindlich. Die Auftr&auml;ge durch den
              K&auml;ufer m&uuml;ssen schriftlich erteilt werden. Der
              schriftlichen Auftragsbest&auml;tigung steht die &Uuml;bermittlung
              der Rechnung gleich.{" "}
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Lieferzusagen von Weingut Wicht beziehen sich auf
              ungef&auml;hre Mengenangaben und daher ist Weingut Wicht
              berechtigt, bis zu 5 % mehr oder weniger als die zugesagte Menge
              zu liefern. Weingut Wicht ist berechtigt, Auftr&auml;ge und
              Bestellungen nur zum Teil anzunehmen und Teillieferungen sind
              durch Weingut Wicht zul&auml;ssig, sofern diese nicht
              ausdr&uuml;cklich vom K&auml;ufer schriftlich abgelehnt werden.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              c) Muster sind unverbindlich, zumal Spezifikationswerte
              N&auml;herungsangaben darstellen und den &uuml;blichen
              biologischen und produktionstechnischen Schwankungen unterliegen.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>3. Preis </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Der Verkaufspreis richtet sich nach der f&uuml;r den Liefertag
              geltenden Preisliste ab Lager von Weingut Wicht zuz&uuml;glich
              Umsatzsteuer, Verpackung und Transportkosten.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Der K&auml;ufer ist zu keinem Skontoabzug berechtigt, es sei
              denn, dies ist ausdr&uuml;cklich schriftlich vereinbart.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>4. Zahlungsmodalit&auml;ten, Verzugszinsen </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Die Rechnungen sind binnen 14 Tagen ohne Abzug zu bezahlen,
              gerechnet ab dem Datum der Rechnungslegung. Davon abweichend
              k&ouml;nnen - jeweils nur schriftlich - andere Zahlungsziele, aber
              auch die Vorauszahlung durch den K&auml;ufer vereinbart werden.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Zahlungen sind mit dem Eingang auf eines der Konten von Weingut
              Wicht erf&uuml;llt.{" "}
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              c) F&uuml;r den Fall des Zahlungsverzuges durch den K&auml;ufer
              werden die Zinsen in der H&ouml;he von 8 % &uuml;ber dem
              jeweiligen Basiszinssatz verrechnet. Die Geltendmachung
              weitgehender Schadenersatzanspr&uuml;che bleibt ausdr&uuml;cklich
              vorbehalten.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Im Falle des Zahlungsverzuges des K&auml;ufers werden alle bis
              dahin ausstehenden Forderungen gegen&uuml;ber dem K&auml;ufer zur
              Zahlung f&auml;llig. Im Falle des Zahlungsverzuges ist Weingut
              Wicht berechtigt, den Vertragsr&uuml;cktritt zu erkl&auml;ren und
              Schadenersatz anstatt Leistung zu verlangen. Es steht Weingut
              Wicht jedoch frei, weitere Auslieferungen von Waren von der
              Zug-um-Zug-Leistung in Form der Barzahlung des K&auml;ufers
              abh&auml;ngig zu machen und vom K&auml;ufer Sicherheiten in
              H&ouml;he des Gesamtauftragswertes zu verlangen.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              e) Wird auch diese Sicherheit innerhalb einer angemessenen Frist
              von 2 Wochen nicht geleistet, so steht jedenfalls nochmals der
              Vertragsr&uuml;cktritt zu. In dem Fall hat Weingut Wicht auch das
              Recht, Schadenersatz zu verlangen.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              f) Ma&szlig;gebliche &Auml;nderungen der wirtschaftlichen
              Verh&auml;ltnisse im Verm&ouml;gen des K&auml;ufers berechtigen
              Weingut Wicht nach Vertragsabschluss, den R&uuml;cktritt vom
              Vertrag zu erkl&auml;ren und Schadenersatz zu verlangen.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              g) Der K&auml;ufer hat in keinem Fall das Recht, mit Forderungen -
              welcher Art auch immer - aufzurechnen oder ein
              Zur&uuml;ckbehaltungsrecht geltend zu machen.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>5. Lieferung </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Liefertermine und Lieferfristen f&uuml;r Weingut Wicht sind nur
              dann verbindlich, wenn eine schriftliche Best&auml;tigung erfolgt
              ist und auch der K&auml;ufer alle Pflichten zur Einhaltung dieser
              Liefertermine und Lieferfristen eingehalten hat, insbesondere auch
              Zahlungspflichten.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              b) Der K&auml;ufer ist verpflichtet, zum vereinbarten Zeitpunkt
              die Ware entgegen zu nehmen. Sollte der K&auml;ufer
              urspr&uuml;nglich vereinbarte Liefertermine nicht einhalten
              wollen, so hat der K&auml;ufer allf&auml;llig dadurch entstehende
              Kosten im Verm&ouml;gen von Weingut Wicht zu tragen,
              unabh&auml;ngig von der Verpflichtung, den Kaufpreis fristgerecht
              zu bezahlen. Dies gilt auch, wenn urspr&uuml;ngliche Liefertermine
              auf Anweisung des K&auml;ufers nicht eingehalten werden sollten.
              Weingut Wicht ist auf jeden Fall berechtigt, nach Setzung einer
              angemessenen Nachfrist vom Vertrag zur&uuml;ckzutreten und in
              diesem Falle auch berechtigt, aber nicht verpflichtet,
              Schadenersatz zu verlangen.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>6. Haftung </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              Die Haftung von Weingut Wicht ist in allen F&auml;llen
              beschr&auml;nkt auf den Fall der groben Fahrl&auml;ssigkeit.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>7. Gefahrentragung </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              Mit der &Uuml;bergabe der bestellten Ware an das den Transport
              ausf&uuml;hrende Unternehmen bzw. im Falle der Selbstabholung
              durch den K&auml;ufer an diesen selbst oder eine von diesem
              beauftragte Person geht die Gefahr auf den K&auml;ufer &uuml;ber.
              Wird der Versand oder die &Uuml;bergabe verz&ouml;gert oder
              unm&ouml;glich gemacht, geht die Gefahr mit der schriftlichen
              Meldung der Versandbereitschaft bzw. &Uuml;bergabebereitschaft auf
              den K&auml;ufer &uuml;ber.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>8. Eigentumsvorbehalt </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Weingut Wicht bleibt bis zur vollst&auml;ndigen Bezahlung
              s&auml;mtlicher Forderungen gegen den K&auml;ufer aus der
              Gesch&auml;ftsverbindung Eigent&uuml;mer der gesamten gelieferten
              Ware. Der K&auml;ufer hat die Pflicht, Weingut Wicht auf Verlangen
              Umfang und Ort der in seinem Besitz befindlichen Vorbehaltsware
              mitzuteilen.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              b) Der K&auml;ufer darf die unter Eigentumsvorbehalt stehenden
              Waren im gew&ouml;hnlichen Gesch&auml;ftsumfeld unter
              Eigentumsvorbehalt weiterver&auml;u&szlig;ern, er hat allerdings
              nicht das Recht, die unter Eigentumsvorbehalt stehenden Waren zu
              verpf&auml;nden oder daran Sicherheitseigentum begr&uuml;nden zu
              lassen. Die aus dem Weiterverkauf oder einem sonstigen Rechtsgrund
              entstehenden Forderungen bez&uuml;glich der unter
              Eigentumsvorbehalt stehenden Waren einschlie&szlig;lich
              s&auml;mtlicher Saldoforderungen aus dem Kontokorrent tritt der
              K&auml;ufer bereits jetzt sicherungshalber in vollem Umfeld an
              Weingut Wicht ab.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Weingut Wicht erm&auml;chtigt den K&auml;ufer, bis auf Widerruf
              die an den Ver&auml;u&szlig;erer abgetretenen Forderungen auf
              Rechnung von Weingut Wicht im eigenen Namen einzuziehen.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Weingut Wicht hat in jedem Fall das Recht, schriftlich das
              Recht zur Weiterver&auml;u&szlig;erung der Vorbehaltsware zu
              widerrufen.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              e) Im Falle des Zahlungsverzuges durch den K&auml;ufer hat Weingut
              Wicht das Recht, die sofortige Herausgabe der im
              Eigentumsvorbehalt stehenden Ware zu verlangen. Der K&auml;ufer
              r&auml;umt daher Weingut Wicht schon jetzt das Recht ein, die
              unter Eigentumsvorbehalt stehende Ware in diesem Fall sofort
              abzuholen bzw. abholen zu lassen und dazu s&auml;mtliche
              Betriebsr&auml;ume des K&auml;ufers umgehend zu betreten. Die
              Aus&uuml;bung dieses Rechts erfolgt allein zu Sicherungszwecken
              und ist keine R&uuml;cktrittserkl&auml;rung vom Vertrag.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              Etwaige Leihbeh&auml;ltnisse von Weingut Wicht, wie insbesondere
              Container, Pr&auml;sentationseinrichtungen und
              Marmeladenst&auml;nder, verbleiben in jedem Fall im Eigentum von
              Weingut Wicht.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>9. R&uuml;gepflichten </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              a) Der K&auml;ufer hat die Pflicht, die Ware unverz&uuml;glich
              beim Empfang genauestens auf Qualit&auml;t und Umfang zu
              untersuchen, Verluste und Besch&auml;digungen beim Transporteur
              bzw. Fr&auml;chter zu melden und bescheinigen zu lassen. Eine
              solche Bescheinigung ist Weingut Wicht bei sonstigem Verlust der
              Rechte unverz&uuml;glich zu &uuml;bermitteln.{" "}
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              b) Weingut Wicht ber&uuml;cksichtigt Beanstandungen nur dann, wenn
              sie vom K&auml;ufer unverz&uuml;glich, sp&auml;testens aber binnen
              7 Tagen nach Erhalt der Ware schriftlich mitgeteilt werden, wobei
              der Tag der Empfangnahme der R&uuml;ge in diese Frist nicht
              einberechnet wird. Die Fristsetzung gilt auch f&uuml;r nicht
              offenkundige M&auml;ngel. F&uuml;r die Einhaltung der Fristen ist
              &uuml;berdies der Zugang der Erkl&auml;rung bei Weingut Wicht
              notwendig. Die &Ouml;ffnung des gelieferten Beh&auml;ltnisses und
              auch nur teilweise Nutzung der Ware f&uuml;hrt zum
              Gew&auml;hrleistungsausschluss.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              c) Die F&auml;lligkeit des Kaufpreises wird von
              M&auml;ngelr&uuml;gen nicht ber&uuml;hrt.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              d) Weingut Wicht haftet daf&uuml;r, dass ihre Waren nicht mit
              M&auml;ngeln behaftet sind. Gew&auml;hrleistungs- und andere
              Anspr&uuml;che k&ouml;nnen jedoch nur dann geltend gemacht werden,
              wenn der K&auml;ufer die vorangef&uuml;hrten
              Untersuchungsr&uuml;gepflichten erf&uuml;llt. Weingut Wicht
              &uuml;bernimmt keine Gew&auml;hrleistung{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              f&uuml;r M&auml;ngel, die durch unsachm&auml;&szlig;ige Behandlung
              durch den K&auml;ufer und auch w&auml;hrend des Transportes und in
              der Lagerung auftreten.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              e) Etwaige Mengenabweichungen stellen Toleranzen dar, die nicht
              als Mangel gelten.
            </span>
            <span className="s2">
              <br /> <br />{" "}
            </span>
            <span className="s3">
              f) Beschaffenheitsmerkmale und Haltbarkeitsgarantien bed&uuml;rfen
              der ausdr&uuml;cklichen und schriftlichen Vereinbarung.
            </span>
            <span className="s2">
              <br />{" "}
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              g) Im Falle einer mangelhaften Warenlieferung ist Weingut Wicht
              nur verpflichtet, den Mangel durch Lieferung eines mangelfreien
              Ersatzes zu beheben, dar&uuml;ber hinausgehende
              Mangelanspr&uuml;che, insbesondere das Recht, den R&uuml;cktritt
              vom Vertrag zu erkl&auml;ren oder eine Kaufpreisminderung zu
              begehren, steht dem K&auml;ufer erst dann zu, wenn Weingut Wicht
              nicht binnen einer vom K&auml;ufer gesetzten angemessenen Frist
              mangelfreie Ware liefert oder sonst den Mangel behebt.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>10. Vertragsklausel </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              Die allf&auml;llige Rechtsunwirksamkeit einer Vertragsklausel
              ber&uuml;hrt die G&uuml;ltigkeit der &uuml;brigen
              Vertragsbestimmungen nicht.{" "}
            </span>
          </p>
          <p className="p2">
            <span className="s3">
              <strong>11. Gerichtsstand </strong>
            </span>
          </p>
          <p className="p1">
            <span className="s3">
              F&uuml;r s&auml;mtliche Streitigkeiten aus dieser
              Gesch&auml;ftsbeziehung mit Weingut Wicht wird ausdr&uuml;cklich
              die Zust&auml;ndigkeit des Bezirksgerichts Graz vereinbart. Sollte
              das Bezirksgericht Graz aufgrund zwingender gesetzlicher
              Bestimmungen nicht zust&auml;ndig sein wird ausdr&uuml;cklich das
              f&uuml;r den Sitz des Unternehmens Weingut Wicht sachlich und
              &ouml;rtlich zust&auml;ndige Gericht vereinbart.{" "}
            </span>
          </p>
        </section>
      </div>
    );
  }
}

export default AGB;
