import React from "react";
import { Link } from "react-router-dom";

export default ({ close }) => (
  <div className="menu hamburgermenu">
    <nav>
      <ul>
        <li>
          <Link to={"/"} onClick={() => close()}>
            Startseite
          </Link>
        </li>
        <li>
          <Link to={"/zimmer"} onClick={() => close()}>
            Zimmer
          </Link>
        </li>
        <li>
          <Link to={"/webshop"} onClick={() => close()}>
            Webshop
          </Link>
        </li>
        <li>
          <Link to={"/warenkorb"} onClick={() => close()}>
            Warenkorb
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);
