import "react-dates/lib/css/_datepicker.css";

import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import Fade from "react-reveal/Fade";
import Image from "../components/Image";
import NumericInput from "react-numeric-input";
import axios from "axios";
import moment from "moment";
import toast from 'react-hot-toast';

class Zimmer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(),
      endDate: moment().add(2, "days"),
      rooms: 1,
      adults: 2,
      children: 0,
      dog: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      price: "",
      sent: false
    };
  }

  calculateCosts = () => {
    let costPerNight = 37;
    let amountOfNights = 0;

    if (this.state.endDate !== null && this.state.startDate !== null) {
      amountOfNights = this.state.endDate.diff(this.state.startDate, "days");
    }

    if (amountOfNights > 1 && amountOfNights < 3) {
      costPerNight = 32;
    } else if (amountOfNights >= 3) {
      costPerNight = 30;
    }

    let cost =
      amountOfNights * this.state.adults * costPerNight * this.state.rooms;
    cost +=
      amountOfNights *
      this.state.children *
      costPerNight *
      0.5 *
      this.state.rooms;

    if (this.state.dog) {
      cost += amountOfNights * 8;
    }

    if(this.state.adults === 1)
    {
      cost += 15;
    }

    return `€ ${cost},-`;
  };

  isFormOkay = () => {
    if (this.state.name.length < 3) {
      return false;
    }

    if (this.state.email.length < 3) {
      return false;
    }

    return true;
  };

  sendMail = event => {
    event.preventDefault();

    if(!this.isFormOkay()){
      return;
    }

    const data = {
      ...this.state,
      price: this.calculateCosts(),
      startDate: this.state.startDate.format("DD.MM.YYYY"),
      endDate: this.state.endDate.format("DD.MM.YYYY")
    };

    axios
      .post("/Mailer/mail.php", data)
      // .post("http://weingut-wicht.at.test/public/Mailer/mail.php", data)
      .then(response => {
        this.setState({ sent: true });

        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }

        toast.success('Anfrage erfolgreich versendet.')
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderForm = () => {
    if (this.state.sent) {
      return (
        <section className="content center form">
          <div className="text has-text-centered">
            <Fade bottom cascade distance={`50px`} duration={1500}>
              <p>
                Vielen Dank für Ihre Anfrage.
                <br />
                Wir freuen uns, dass Sie gerne bei uns Wicht’s übernachten
                würden und melde uns in Kürze bei Ihnen.
              </p>
            </Fade>
          </div>
        </section>
      );
    }

    const duration = window.innerWidth < 400 ? 1000 : 3000;
    const distance = window.innerWidth < 400 ? `30px` : `100px`;

    return (
      <form action="/Mailer/mail.php" onSubmit={e => this.sendMail(e)}>
        <section className="content center form">
          <div className="text has-text-centered">
            <Fade bottom cascade duration={duration} distance={distance}>
              <h2>Zimmeranfrage</h2>
              <div className="row">
                <p>Reisezeitraum</p>
                <DateRangePicker
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({ startDate, endDate })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  startDatePlaceholderText={"Anreise"}
                  endDatePlaceholderText={"Abreise"}
                  displayFormat={"DD.MM.YYYY"}
                  firstDayOfWeek={1}
                  minimumNights={1}
                />
              </div>
              <div className="options">
                <div className="option">
                  Erwachsene{this.state.adults === 1 ? "r" : ""}
                  <NumericInput
                    mobile
                    value={this.state.adults}
                    onChange={number => this.setState({ adults: number })}
                    min={1}
                    max={8}
                  />
                </div>
                <div className="option">
                  Kind{this.state.children > 1 ? "er" : ""} (6-12 Jahre)
                  <NumericInput
                    mobile
                    value={this.state.children}
                    onChange={number => this.setState({ children: number })}
                    min={0}
                    max={8}
                  />
                </div>
              </div>
              <div className="row">
                <label className={"checkbox"}>
                  <input
                    type="checkbox"
                    onChange={e => this.setState({ dog: e.target.checked })}
                  />
                  {this.state.adults + this.state.children > 1
                    ? "Wir reisen mit Hund"
                    : "Ich reise mit Hund"}
                </label>
              </div>
              <div className="row costs">
                <label>Kosten </label>
                {this.calculateCosts()}
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Vollständiger Name"
                  value={this.state.name}
                  onChange={event =>
                    this.setState({ name: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  placeholder="E-Mail"
                  value={this.state.email}
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  placeholder="Telefonnummer"
                  value={this.state.phone}
                  onChange={event =>
                    this.setState({ phone: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <textarea
                  type="text"
                  name="message"
                  placeholder="Anmerkungen"
                  value={this.state.message}
                  onChange={event =>
                    this.setState({ message: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <input
                    type="submit"
                    value={
                      this.isFormOkay()
                          ? "unverbindlich Anfragen"
                          : "unverbindlich Anfragen (Daten fehlen)"
                    }
                    disabled={!this.isFormOkay()}
                />
              </div>
              <div className="row">
                <p>
                  <small>
                    Stornobedingungen
                    <br />
                    Bis 60 Tage vor Reiseantritt kostenlos,
                    <br />
                    bis 30 Tage vor Reiseantritt 50 Prozent,
                    <br />
                    danach 100 Prozent.
                  </small>
                </p>
              </div>
            </Fade>
          </div>
        </section>
      </form>
    );
  };

  render() {
    const duration = window.innerWidth < 400 ? 1000 : 3000;
    const distance = window.innerWidth < 400 ? `30px` : `100px`;
    return (
      <div>
        <section className="content hero">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1185_2.jpg"} />
            </Fade>
          </div>
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <h1>
                Gemütlich und familiär, euer Wohl liegt uns am Herzen.
                <br />
                Ob klein, ob groß, ob mit oder ohne Haar’
                <br />
                in unseren Zimmern ist jeder willkommen.
              </h1>
            </Fade>
          </div>
        </section>
        <section className="content center">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Wir Wicht’s mögen es einfach. Unsere vier Zimmer mit herrlichem
                Blick auf die Hügellandschaft von Kitzeck und deren Weinberge
                sind gemütlich und mit allem was man benötigt ausgestattet.
              </p>
            </Fade>
          </div>
        </section>
        <section className="content">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1136_2.jpg"} />
            </Fade>
          </div>
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Unser Häuschen liegt abseits vom Trubel, ist aber dennoch leicht
                zu erreichen. Bei uns schläft man wie ein Baby, denn die ruhige
                Lage macht sich auch in der Nacht bezahlt.
              </p>
            </Fade>
          </div>
        </section>
        <section className="content">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>Zimmerpreise unserer Frühstückspension:</p>
              <p>
               1 Doppelzimmer:<br/>
               € 37,- pro Person für eine Nacht
                <br />
                € 32,- pro Person ab zwei Nächten
                <br />
                € 30,- pro Person ab drei Nächten
              </p>
              <p>
              Einen Aufschlag von €15 wenn ein Doppelzimmer als Einzelzimmer genutzt wird.<br/><br/>

              Preise sind inklusive Mehrwertsteuer sowie Frühstück, exklusive Kurtaxe.<br/><br/>

              Auch Vierbeiner sind bei uns herzlich Willkommen. Dafür verrechnen wir pro Nacht € 8,-<br/>
              </p>
            </Fade>
          </div>
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1136_Copy.jpg"} />
            </Fade>
          </div>
        </section>
        <section className="content yellow">
          <div className="text">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Auf einen Blick:
                <br />
                Vier Doppelzimmer
                <br />
                Leckeres Frühstück inkludiert
                <br />
                Urlaub mit Kind & Hund
                <br />
                Besonders ruhige Lage
                <br />
                Großes Areal um die Pension
                <br />
                Viele Wandermöglichkeiten sowie Radwege
                <br />
                Gemütlich und familiär
                <br />
                Frühstück von 07:30–10:00 Uhr
                <br />
                Check-In ab 14:00 Uhr
                <br />
                Check-Out bis 11:00 Uhr
              </p>
            </Fade>
          </div>
          <div className="image">
            <Image src={"IMG_1202-3_2.jpg"} />
          </div>
        </section>
        <section className="content">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1284.jpg"} />
            </Fade>
          </div>
          <div className="text">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Was uns Wicht’s wichtig ist:
                <br />
                Fühlt euch wie zu Hause bei uns, aber bitte behandelt euer
                Urlaubszuhause auch so als wäre es euer eigenes
                <br />
                Rauchen nur an der frischen Luft
                <br />
                Euer Hündchen muss sich bitte ebenfalls benehmen
                {/*<br />*/}
                {/*Ihr dürft eure eigene Jause sowie Getränke natürlich selbst*/}
                {/*mitbringen und überall genießen*/}
              </p>
            </Fade>
          </div>
        </section>
        <section className="content">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                In der Früh bekommt ihr ein frisches Frühstück von unserer
                Hausherrin Christa serviert. Wir Wicht’s setzen auf
                Regionalität, darum wird das Gemüse und Obst vom eigenen Garten,
                die Eier von den Hennen des Hauses und andere regionale Zutaten
                für unser leckeres Frühstück verwendet.
              </p>
            </Fade>
          </div>
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"IMG_1284_2.jpg"} />
            </Fade>
          </div>
        </section>
        <section className="content yellow">
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <p>
                Lustet es euch nach einem langen Tag nach einem Happen essen und
                ihr möchtet den Abend in unserem Garten verbringen, bieten wir
                euch gerne ein Eierspeis’ oder eine andere Kleinigkeit an.
                Ansonsten wird bei uns nicht groß aufgekocht, dafür gibt es in
                unserer Umgebung genug wo ihr hingehen könnt und wir Wicht’s
                geben euch gerne Auskunft darüber.
              </p>
            </Fade>
          </div>
          <div className="image">
            <Image src={"hendl.jpg"} />
          </div>
        </section>
        {this.renderForm()}
      </div>
    );
  }
}

export default Zimmer;
