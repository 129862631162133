import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import NumericInput from "react-numeric-input";

const ShopProduct = props => {
  const [amount, setAmount] = useState(1);

  const price = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  }).format(props.price * amount);

  return (
    <section className="shopProduct">
      <div className="image">
        <Image src={props.img} />
      </div>
      <div className="text form">
        <h3>{props.name}</h3>
        <p>{props.description}</p>
        <p>{props.meta}</p>
        <div className="stepper">
          Flaschen
          <NumericInput
            mobile
            value={amount}
            onChange={number => setAmount(number)}
            min={0}
            max={50}
          />
          <input
            type="button"
            value={`In den Warenkorb ${price}`}
            onClick={() => props.addToCart(props, amount)}
          />
        </div>
      </div>
    </section>
  );
  // }
};

ShopProduct.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.string,
  price: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string
};

ShopProduct.defaultProps = {
  name: "<NAME>",
  description: "",
  price: 5
};

export default ShopProduct;
