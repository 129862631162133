import React, { Component } from "react";
import Image from "../components/Image";
import ShopProduct from "../components/ShopProduct";
import SplitButtons from "../components/SplitButtons";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Fade from "react-reveal/Fade";

class Webshop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: "Alle",
    };

    // this.categories = ["Alle", "Wein", "Schaumwein", "Schnaps", "Alkoholfrei"];
    this.categories = ["Alle", "Schnaps"];
  }

  renderProducts = () => {
    const products = [];
    this.props.data.shop
      .sort((a, b) => {
        const indexA = this.categories.indexOf(a.get("category"));
        const indexB = this.categories.indexOf(b.get("category"));

        if (indexA > indexB) {
          return 1;
        } else if (indexB > indexA) {
          return -1;
        }
        return 0;
      })
      .filter(
        (product) =>
          this.state.activeItem === "Alle" ||
          this.state.activeItem === product.get("category")
      )
      .forEach((product, i) => products.push(product));

    return (
      <TransitionGroup component="div">
        {products.map((product) => (
          <CSSTransition
            timeout={1000}
            classNames="rtg-basic"
            key={`webshop_product_${product.get("name")}`}
          >
            <ShopProduct
              {...product.toJS()}
              key={`webshop_product_${product.get("name")}`}
              addToCart={(product, amount) =>
                this.props.addToCart(product, amount)
              }
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  };

  render() {
    const duration = window.innerWidth < 400 ? 1000 : 3000;
    const distance = window.innerWidth < 400 ? `30px` : `100px`;

    return (
      <div>
        <section className="content hero">
          <div className="image">
            <Fade bottom duration={duration} distance={distance}>
              <Image src={"Webshop.jpg"} />
            </Fade>
          </div>
          <div className="text has-text-centered">
            <Fade bottom duration={duration} distance={distance}>
              <h1>
                Bei uns Wicht’s wird steirischer Qualitätswein der besonderen
                Art produziert. Besonders weil unsere Trauben auf einer für Wein
                untypischen Hanglage angebaut werden. Die Nordost Lage galt als
                ein wahres No-Go im Weinbau und doch konnten wir in den letzten
                Jahren durch die im Sommer kühlere Temperatur am Nordhang
                profitieren, diese garantiert nämlich auch in den heißesten
                Sommermonaten genug Zeit für die Reife der Trauben.
              </h1>
            </Fade>
          </div>
        </section>
        <section>
          <SplitButtons
            buttons={this.categories}
            activeItem={this.state.activeItem}
            setActiveItem={(filter) => this.setState({ activeItem: filter })}
          />
        </section>
        <section className="shopProducts">{this.renderProducts()}</section>
      </div>
    );
  }
}

export default Webshop;
