import { Link } from "react-router-dom";
import React, { Component } from "react";
import axios from "axios";
import Image from "./Image";
import Fade from "react-reveal/Fade";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      occasion: "",
      date: "",
      message: "",
      sent: false
    };
  }

  sendMail = event => {
    event.preventDefault();

    console.log("sending..");

    axios
      .post("/Mailer/mail.php", this.state)
      // .post("http://weingut-wicht.at.test/public/Mailer/mail.php", this.state)
      .then(response => {
        this.setState({ sent: true });

        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }

        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderForm = () => {
    if (this.state.sent) {
      return (
        <div>
          <h5>Vielen Dank für Ihre Nachricht</h5>
          <p>Ich werde mich bald bei Ihnen melden.</p>
        </div>
      );
    } else {
      return (
        <form action="/Mailer/mail.php" onSubmit={e => this.sendMail(e)}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={this.state.name}
              onChange={event => this.setState({ name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="email"
              placeholder="E-Mail"
              value={this.state.email}
              onChange={event => this.setState({ email: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="phone"
              placeholder="Telefonnummer"
              value={this.state.phone}
              onChange={event => this.setState({ phone: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="occasion"
              placeholder="Anlass"
              value={this.state.occasion}
              onChange={event =>
                this.setState({ occasion: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              name="date"
              placeholder="Datum"
              value={this.state.date}
              onChange={event => this.setState({ date: event.target.value })}
            />
          </div>
          <div className="form-group">
            <textarea
              type="text"
              name="message"
              placeholder="Hallo Lisa,
                        wir heiraten am 24.04...."
              value={this.state.message}
              onChange={event => this.setState({ message: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input type="submit" value={"senden"} />
          </div>
        </form>
      );
    }
  };

  render() {
    return (
      <footer className="desktop">
        <section className="content yellow">
          <div className="text has-text-centered">
            <Fade bottom duration={3000} distance={`100px`}>
              <p>
                Familie Wicht <br />
                <a
                  href="https://goo.gl/maps/CK2rbAsByY4BB7Mf6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Steinriegel 1 <br />
                  8442 Kitzeck im Sausal
                </a>{" "}
                <br />
                <a href={`tel:+436645224635`}>+43 664 522 46 35</a>
                <br />
                <a href={`tel:+436641293166`}>+43 664 129 31 66</a>
              </p>
            </Fade>
          </div>
          <a
            href="https://goo.gl/maps/CK2rbAsByY4BB7Mf6"
            target="_blank"
            rel="noopener noreferrer"
            className={"image"}
          >
            <Image src={"maps.png"} />
          </a>
        </section>
        <div className="bar">
          <div className="wrapper">
            <div className="row middle-sm">
              <div className="col-xs-12 col-sm-8 has-text-centered text">
                <a href="mailto:info@weingut-wicht.at">info@weingut-wicht.at</a>
                <Link to="/datenschutz">Datenschutz & Impressum</Link>
                <Link to="/agb">AGB</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
