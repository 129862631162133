import React, { Component } from "react";

import CartInput from "../components/CartInput";
import CartProduct from "../components/CartProduct";
import Fade from "react-reveal/Fade";
import Image from "../components/Image";
import axios from "axios";
import toast from 'react-hot-toast';

class Cart extends Component {
  isFormOkay = () => {
    if (this.props.address.get("name").length < 3) {
      return false;
    }

    if (this.props.address.get("address").length < 3) {
      return false;
    }

    if (this.props.address.get("zip").length < 3) {
      return false;
    }

    if (this.props.address.get("city").length < 3) {
      return false;
    }

    if (this.props.address.get("email").length < 3) {
      return false;
    }

    return true;
  };

  calculateShippingCost = () => {
    return Math.ceil(this.props.items / 12) * 8;
  };

  calculatePrices = (formatted = false) => {
    const bruttoProducts = this.props.sum;
    const bruttoShipping = this.calculateShippingCost();

    const shippingCost = this.calculateShippingCost();

    const nettoProducts = this.props.sum / 1.2;
    const taxProducts = this.props.sum - nettoProducts;

    const nettoShipping = shippingCost / 1.2;
    const taxShipping = shippingCost - nettoShipping;

    const total = this.props.sum + shippingCost;

    if(formatted){
      return {
        shippingCost: this.formatNumber(shippingCost),
        bruttoProducts: this.formatNumber(bruttoProducts),
        bruttoShipping: this.formatNumber(bruttoShipping),
        nettoProducts: this.formatNumber(nettoProducts),
        nettoShipping: this.formatNumber(nettoShipping),
        taxProducts: this.formatNumber(taxProducts),
        taxShipping: this.formatNumber(taxShipping),
        total: this.formatNumber(total)
      };
    }

    return {
      shippingCost,
      bruttoProducts,
      bruttoShipping,
      nettoProducts,
      nettoShipping,
      taxProducts,
      taxShipping,
      total
    };
  };

  sendMail = event => {
    event.preventDefault();

    // console.log("sending..");

    if(!this.isFormOkay()){
      return;
    }

    const data = {
      cart: this.props.cart.toJS(),
      address: this.props.address.toJS(),
      shipping: this.calculateShippingCost(),
      prices: this.calculatePrices(true)
    };

    // console.log(data);
    // Name bereinigen wegen doppelt absenden
    this.props.setAddress('name', "");

    axios
      .post("/Mailer/shop.php", data)
      // .post("http://weingut-wicht.at.test/public/Mailer/shop.php", data)
      .then(response => {
        this.props.clearCart();

        window.scrollTo(0, 0);

        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }

        toast.success('Anfrage erfolgreich versendet.')
        this.props.setAddress('name', false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderProducts = () => {
    const products = [];
    this.props.cart.forEach(product =>
      products.push(
        <CartProduct
          {...product}
          setAmountInCart={(product, amount) =>
            this.props.setAmountInCart(product, amount)
          }
        />
      )
    );
    return products;
  };

  renderForm = () => {
    const address = this.props.address.toJS();

    return (
      <form action="/Mailer/shop.php" onSubmit={e => this.sendMail(e)}>
        <section className="form">
          <CartInput
            placeholder={"Vollständiger Name"}
            name={"name"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"Firmenname (optional)"}
            name={"company"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"Straße"}
            name={"address"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"PLZ"}
            name={"zip"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"Ort"}
            name={"city"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"Land"}
            name={"country"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"E-Mail"}
            name={"email"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <CartInput
            placeholder={"Telefonnummer (optional)"}
            name={"phone"}
            address={address}
            setAddress={(field, value) => this.props.setAddress(field, value)}
          />
          <div className="form-group">
            <input
              type="submit"
              value={
                this.isFormOkay()
                  ? "Jetzt Bestellen"
                  : "Jetzt Bestellen (Daten fehlen)"
              }
              disabled={!this.isFormOkay()}
            />
          </div>
        </section>
      </form>
    );
  };

  formatNumber = (number) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR"
    }).format(number);
  };


  render() {
    const duration = window.innerWidth < 400 ? 1000 : 3000;
    const distance = window.innerWidth < 400 ? `30px` : `100px`;

    console.log(this.calculatePrices());
    console.log(this.calculatePrices(true));

    if (this.props.shopSent) {
      return (
          <section className="content center form">
            <div className="text has-text-centered">
              <Fade bottom duration={duration} distance={distance}>
                <p>
                  Vielen Dank für Ihre Bestellung bei uns Wicht’s.
                  <br />
                  Sie erhalten in Kürze Ihre Bestellbestätigung per E-Mail.
                </p>
              </Fade>
            </div>
          </section>
      );
    }

    if (this.props.items === 0) {
      return (
        <div>
          <section className="content hero">
            <div className="image">
              <Fade bottom duration={duration} distance={distance}>
                <Image src={"Webshop.jpg"} />
              </Fade>
            </div>
            <div className="text has-text-centered">
              <Fade bottom duration={duration} distance={distance}>
                <h1>Ihr Warenkorb ist noch leer.</h1>
              </Fade>
            </div>
          </section>
        </div>
      );
    }

    const prices = this.calculatePrices();

    return (
      <div>
        <section className="cartOverview">
          <section className="cart">
            <h1>1. Ihr Warenkorb</h1>
            {this.renderProducts()}
            <div className="cartTotal has-text-right">
              Zwischensumme exkl. 13% USt: {this.formatNumber(prices.nettoProducts)}
            </div>
            <div className="cartTotal has-text-right">
              Versandkosten exkl. 20% USt: {this.formatNumber(prices.nettoShipping)}
            </div>
            <div className="cartTotal has-text-right" style={{borderTop: '1px solid #000'}}>
              13% USt auf {this.formatNumber(prices.nettoProducts)}: {this.formatNumber(prices.taxProducts)}
            </div>
            <div className="cartTotal has-text-right">
              20% USt auf {this.formatNumber(prices.nettoShipping)}: {this.formatNumber(prices.taxShipping)}
            </div>
            <div className="cartTotal has-text-right" style={{borderTop: '2px solid #000'}}>
              Summe inkl. USt.: {this.formatNumber(prices.total)}
            </div>
          </section>
          <section className="address">
            <h1>2. Ihre Anschrift</h1>
            {this.renderForm()}
          </section>
        </section>
      </div>
    );
  }
}

export default Cart;
