import React, { Component } from "react";

class Datenschutz extends Component {
  render() {
    return (
      <div>
        <section className="datenschutz">
          <h1>Datenschutzerklärung</h1>

          <p>
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
            Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
            „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
            verbundenen Webseiten, Funktionen und Inhalte sowie externen
            Onlinepräsenzen, wie z.B. unser Social Media Profile auf.
            (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick
            auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder
            „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
            Datenschutzgrundverordnung (DSGVO).
          </p>

          <p>
            Informationspflicht laut §5 E-Commerce Gesetz, §14
            Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
            laut §25 Mediengesetz
          </p>
          <p>
            Familie Wicht <br />
            Steinriegel 1 <br />
            8442 Kitzeck im Sausal <br />
            <a href={`tel:+436645224635`}>+43 664 522 46 35</a>
            <br />
            <a href={`tel:+436641293166`}>+43 664 129 31 66</a>
          </p>

          <h3>Websitegestaltung & Umsetzung</h3>
          <p>
            sofresh <a href="https://sofresh.digital">www.sofresh.digital</a>
          </p>

          <h2>Arten der verarbeiteten Daten:</h2>

          <ul>
            <li>Bestandsdaten (z.B., Namen, Adressen).</li>
            <li>Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
            <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
            <li>
              Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
              Zugriffszeiten).
            </li>
            <li>
              Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
              IP-Adressen).
            </li>
          </ul>

          <h2>Kategorien betroffener Personen</h2>

          <p>
            Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir
            die betroffenen Personen zusammenfassend auch als „Nutzer“).
          </p>

          <h2>Zweck der Verarbeitung</h2>

          <ul>
            <li>
              Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
              Inhalte.
            </li>
            <li>
              Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
            </li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Reichweitenmessung/Marketing</li>
          </ul>

          <h2>Verwendete Begrifflichkeiten</h2>

          <p>
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            „betroffene Person“) beziehen; als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere
            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
            oder zu einem oder mehreren besonderen Merkmalen identifiziert
            werden kann, die Ausdruck der physischen, physiologischen,
            genetischen, psychischen, wirtschaftlichen, kulturellen oder
            sozialen Identität dieser natürlichen Person sind.
          </p>

          <p>
            „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
            Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
            und umfasst praktisch jeden Umgang mit Daten.
          </p>

          <p>
            Als „Verantwortlicher“ wird die natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten entscheidet, bezeichnet.
          </p>

          <h2>Maßgebliche Rechtsgrundlagen</h2>

          <p>
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
            Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
            Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
            1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
            zur Erfüllung unserer Leistungen und Durchführung vertraglicher
            Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b
            DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
            unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
            und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
          </p>

          <h2>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h2>

          <p>
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
            Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
            offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
            die Daten gewähren, erfolgt dies nur auf Grundlage einer
            gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
            Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
            DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
            haben, eine rechtliche Verpflichtung dies vorsieht oder auf
            Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
            Beauftragten, Webhostern, etc.).
          </p>

          <p>
            Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
            sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies
            auf Grundlage des Art. 28 DSGVO.
          </p>

          <h2>Übermittlungen in Drittländer</h2>

          <p>
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
            Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten
            oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
            Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht,
            erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen
            Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer
            rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten
            Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher
            Erlaubnisse, verarbeiten oder lassen wir die Daten in einem
            Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art.
            44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf
            Grundlage besonderer Garantien, wie der offiziell anerkannten
            Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B.
            für die USA durch das „Privacy Shield“) oder Beachtung offiziell
            anerkannter spezieller vertraglicher Verpflichtungen (so genannte
            „Standardvertragsklauseln“).
          </p>

          <h2>Rechte der betroffenen Personen</h2>

          <p>
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend Art. 15 DSGVO.
          </p>

          <p>
            Sie haben entsprechend. Art. 16 DSGVO das Recht, die
            Vervollständigung der Sie betreffenden Daten oder die Berichtigung
            der Sie betreffenden unrichtigen Daten zu verlangen.
          </p>

          <p>
            Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
            dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
            nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung
            der Daten zu verlangen.
          </p>

          <p>
            Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
            die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu
            erhalten und deren Übermittlung an andere Verantwortliche zu
            fordern.
          </p>

          <p>
            Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei
            der zuständigen Aufsichtsbehörde einzureichen.
          </p>

          <h2>Widerrufsrecht</h2>

          <p>
            Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3
            DSGVO mit Wirkung für die Zukunft zu widerrufen
          </p>

          <h2>Widerspruchsrecht</h2>

          <p>
            Sie können der künftigen Verarbeitung der Sie betreffenden Daten
            nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
            Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
            Direktwerbung erfolgen.
          </p>

          <h2>Cookies und Widerspruchsrecht bei Direktwerbung</h2>

          <p>
            Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
            Nutzer gespeichert werden. Innerhalb der Cookies können
            unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär
            dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie
            gespeichert ist) während oder auch nach seinem Besuch innerhalb
            eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw.
            „Session-Cookies“ oder „transiente Cookies“, werden Cookies
            bezeichnet, die gelöscht werden, nachdem ein Nutzer ein
            Onlineangebot verlässt und seinen Browser schließt. In einem solchen
            Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop
            oder ein Login-Staus gespeichert werden. Als „permanent“ oder
            „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen
            des Browsers gespeichert bleiben. So kann z.B. der Login-Status
            gespeichert werden, wenn die Nutzer diese nach mehreren Tagen
            aufsuchen. Ebenso können in einem solchen Cookie die Interessen der
            Nutzer gespeichert werden, die für Reichweitenmessung oder
            Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
            Cookies von anderen Anbietern als dem Verantwortlichen, der das
            Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur
            dessen Cookies sind spricht man von „First-Party Cookies“).
          </p>
          <p>
            Wir können temporäre und permanente Cookies einsetzen und klären
            hierüber im Rahmen unserer Datenschutzerklärung auf.
          </p>

          <p>
            Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
            gespeichert werden, werden sie gebeten die entsprechende Option in
            den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
            Cookies können in den Systemeinstellungen des Browsers gelöscht
            werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
            dieses Onlineangebotes führen.
          </p>

          <p>
            Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
            Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
            Dienste, vor allem im Fall des Trackings, über die US-amerikanische
            Seite
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            oder die EU-Seite
            <a href="http://www.youronlinechoices.com/">
              http://www.youronlinechoices.com/
            </a>
            erklärt werden. Des Weiteren kann die Speicherung von Cookies
            mittels deren Abschaltung in den Einstellungen des Browsers erreicht
            werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle
            Funktionen dieses Onlineangebotes genutzt werden können.
          </p>

          <h2>Löschung von Daten</h2>

          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und
            18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern
            nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
            werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
            Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
            nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
            Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
            D.h. die Daten werden gesperrt und nicht für andere Zwecke
            verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
            steuerrechtlichen Gründen aufbewahrt werden müssen.
          </p>

          <p>
            Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
            insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen,
            Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der
            Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit
            Grundstücken und für 10 Jaahre bei Unterlagen im Zusammenhang mit
            elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk-
            und Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten
            erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in
            Anspruch genommen wird.
          </p>

          <h2>Hosting</h2>

          <p>
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, Sicherheitsleistungen sowie Technische
            Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
            Onlineangebotes einsetzen.
          </p>

          <p>
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
            Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
            Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an
            einer effizienten und sicheren Zurverfügungstellung dieses
            Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO
            (Abschluss Auftragsverarbeitungsvertrag).
          </p>

          <h2>Erhebung von Zugriffsdaten und Logfiles</h2>

          <p>
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
            berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
            Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören
            Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs,
            übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
            zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
          </p>

          <p>
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
          </p>

          <h2>Google Analytics</h2>

          <p>
            Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
            Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
            unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
            Google Analytics, einen Webanalysedienst der Google LLC („Google“)
            ein. Google verwendet Cookies. Die durch das Cookie erzeugten
            Informationen über Benutzung des Onlineangebotes durch die Nutzer
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert.
          </p>

          <p>
            Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
            hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten (
            <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
            </a>
            ).
          </p>

          <p>
            Google wird diese Informationen in unserem Auftrag benutzen, um die
            Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um
            Reports über die Aktivitäten innerhalb dieses Onlineangebotes
            zusammenzustellen und um weitere, mit der Nutzung dieses
            Onlineangebotes und der Internetnutzung verbundene Dienstleistungen,
            uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten
            pseudonyme Nutzungsprofile der Nutzer erstellt werden.
          </p>

          <p>
            Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung
            ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt.
          </p>

          <p>
            Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht
            mit anderen Daten von Google zusammengeführt. Die Nutzer können die
            Speicherung der Cookies durch eine entsprechende Einstellung ihrer
            Browser-Software verhindern; die Nutzer können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
            Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung
            dieser Daten durch Google verhindern, indem sie das unter folgendem
            Link verfügbare Browser-Plugin herunterladen und installieren:
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
          </p>

          <p>
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten erfahren Sie auf den Webseiten von
            Google:
            <a href="https://www.google.com/intl/de/policies/privacy/partners">
              https://www.google.com/intl/de/policies/privacy/partners
            </a>
            („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps
            unserer Partner“),
            <a href="http://www.google.com/policies/technologies/ads">
              http://www.google.com/policies/technologies/ads
            </a>
            („Datennutzung zu Werbezwecken“),
            <a href="http://www.google.de/settings/ads">
              http://www.google.de/settings/ads
            </a>
            („Informationen verwalten, die Google verwendet, um Ihnen Werbung
            einzublenden“).
          </p>

          <h2>Einbindung von Diensten und Inhalten Dritter</h2>

          <p>
            Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
            und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
            Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
            Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
            oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet
            als “Inhalte”).
          </p>

          <p>
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
            IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die
            Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
            damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen
            uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
            IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
            Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
            Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
            Marketingzwecke verwenden. Durch die "Pixel-Tags" können
            Informationen, wie der Besucherverkehr auf den Seiten dieser Website
            ausgewertet werden. Die pseudonymen Informationen können ferner in
            Cookies auf dem Gerät der Nutzer gespeichert werden und unter
            anderem technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
            unseres Onlineangebotes enthalten, als auch mit solchen
            Informationen aus anderen Quellen verbunden werden können.
          </p>

          <h2>Google Maps</h2>

          <p>
            Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
            Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
            ein. Datenschutzerklärung:
            <a href="https://www.google.com/policies/privacy/">
              https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:
            <a href="https://adssettings.google.com/authenticated">
              https://adssettings.google.com/authenticated
            </a>
            .
          </p>
        </section>
      </div>
    );
  }
}

export default Datenschutz;
