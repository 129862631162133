import React, { useState } from "react";
import PropTypes from "prop-types";
import SplitButton from "./SplitButton";

const SplitButtons = (props) => {
  const [activeItem, setActiveItem] = useState("Alle");

  const activeFunc =
    typeof props.setActiveItem !== "undefined"
      ? props.setActiveItem
      : setActiveItem;
  const activeItemToPass =
    typeof props.activeItem !== "undefined" ? props.activeItem : activeItem;

  return (
    <div className="splitButtons">
      <SplitButton
        button={"Alle"}
        setActiveItem={activeFunc}
        activeItem={activeItemToPass}
      />
      {/* <SplitButton
        button={"Wein"}
        setActiveItem={activeFunc}
        activeItem={activeItemToPass}
      />
      <SplitButton
        button={"Schaumwein"}
        setActiveItem={activeFunc}
        activeItem={activeItemToPass}
      /> */}
      <SplitButton
        button={"Schnaps"}
        setActiveItem={activeFunc}
        activeItem={activeItemToPass}
      />
      {/* <SplitButton
        button={"Alkoholfrei"}
        setActiveItem={activeFunc}
        activeItem={activeItemToPass}
      /> */}
      {/*{props.buttons.forEach(button => (*/}
      {/*  <SplitButton*/}
      {/*    button={button}*/}
      {/*    setActiveItem={setActiveItem}*/}
      {/*    activeItem={activeItem}*/}
      {/*  />*/}
      {/*))}*/}
    </div>
  );
};

SplitButtons.propTypes = {
  buttons: PropTypes.array,
};

export default SplitButtons;
